import _styled from "styled-components";
import { DisplayedChatMessage } from '../Chat.types';
import { Typography, TypographySize, TypographyWeight } from '~/components/core';
const BatchUserMessageContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.375rem",
  "borderLeftWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(128 237 153 / var(--tw-border-opacity))",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "paddingLeft": "1rem"
});
const NameAndMessageContainer = _styled.div({
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "0.375rem"
});
interface BatchUserMessageProps {
  chatMessage: DisplayedChatMessage;
}
const BatchUserMessage = ({
  chatMessage
}: BatchUserMessageProps) => <BatchUserMessageContainer>
    <NameAndMessageContainer>
      <Typography size={TypographySize.X_SMALL} weight={TypographyWeight.BOLD}>
        You
      </Typography>
      <Typography size={TypographySize.SMALL} weight={TypographyWeight.NORMAL}>
        {chatMessage.batch?.prompt}
      </Typography>
    </NameAndMessageContainer>
  </BatchUserMessageContainer>;
export default BatchUserMessage;