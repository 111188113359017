import _styled from "styled-components";
import { CerbosWorkflowAction } from '@kindo/universal';
import { formatDistanceToNowStrict } from 'date-fns';
import { useRouter } from 'next/router';
import { isMobileOnly } from 'react-device-detect';
import WorkstationTitle from './WorkstationTitle';
import { Badge } from '~/components/Badge';
import { Button, ButtonType, Icon } from '~/components/core';
import { Dropdown, DropdownItem } from '~/components/Dropdown';
import { NavButton } from '~/components/Nav/NavButton';
import { CopyButton } from '~/components/shared';
import { BASE_NEXT_URL, ROOT_ROUTE, WORKFLOW_BUILDER_ROUTE, WORKFLOW_ID_QUERY_PARAM, WORKSTATION_ROUTE } from '~/constants';
import { useAppDispatch, useCanShareWorkflow } from '~/hooks';
import { Modal, modalActions } from '~/redux/reducers/modalSlice';
import { nextTrpc } from '~/trpc';
import { Workflow } from '~/types';
const WorkstationHeaderContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem",
  "paddingBottom": "1.5rem"
});
const WorkstationHeaderActionsRowContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "justifyContent": "space-between"
});
const ActionButtonsAndDateContainer = _styled.div({
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem"
});
interface WorkstationHeaderProps {
  chatId: string | undefined;
  refetchChat: () => void;
  title: string;
  workflow: Workflow | undefined;
}
const WorkstationHeader: React.FC<WorkstationHeaderProps> = ({
  chatId,
  refetchChat,
  title,
  workflow
}) => {
  const workflowId = workflow?.id;

  // Redux
  const dispatch = useAppDispatch();
  const openShareWorkflowModal = () => {
    dispatch(modalActions.openModal({
      type: Modal.SHARE_WORKFLOW,
      workflowId: workflowId ?? ''
    }));
  };
  const openShareChatModal = () => {
    dispatch(modalActions.openModal({
      type: Modal.SHARE_CHAT,
      chatId: chatId ?? ''
    }));
  };

  // Custom hooks
  const router = useRouter();
  const {
    canShareWorkflow
  } = useCanShareWorkflow(workflowId);

  // Queries
  const {
    data: isAllowedToEditWorkflow
  } = nextTrpc.workflows.isAllowed.useQuery({
    id: workflowId ?? '',
    action: CerbosWorkflowAction.EDIT_WORKFLOW
  }, {
    enabled: !!workflowId
  });

  // Handlers
  const handleRestartWorkflow = () => {
    // Remove chatId if it exists
    const {
      chatId: _paramChatId,
      ...query
    } = router.query;
    void router.push({
      pathname: router.pathname,
      query
    });
  };
  const shareWorkflowLink = workflowId ? `${BASE_NEXT_URL}${WORKSTATION_ROUTE}?${WORKFLOW_ID_QUERY_PARAM}=${workflowId}` : '';
  const isPublic = !!workflow?.publicRole;
  return <WorkstationHeaderContainer>
      <WorkstationHeaderActionsRowContainer>
        {workflow?.category ? <Badge bg="none" css={{
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgb(225 223 255 / var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgb(60 48 131 / var(--tw-text-opacity))"
      }}>
            {workflow?.category}
          </Badge> : <div />}
        <ActionButtonsAndDateContainer>
          {/* If the user doesn't have permission to share */}
          {/* But the workflow is public, let them copy the link */}
          {workflowId && isPublic && !canShareWorkflow && <CopyButton data-id="workflow_copy" icon={Icon.SHARE} label="Share Agent" successText="Agent Link Copied" text={shareWorkflowLink} />}
          {!!workflowId && <>
              {canShareWorkflow && <Dropdown data-id="workflow_share" highlightSelected={false} trigger={<Button label="Share" onClick={() => {}} startIcon={Icon.SHARE} type={ButtonType.OUTLINED} />}>
                  <DropdownItem onClick={() => openShareWorkflowModal()} title="Share Agent" />
                  <DropdownItem onClick={() => openShareChatModal()} title="Share Chat" />
                </Dropdown>}
              <Button data-id="workflow_restart" label="Restart" onClick={handleRestartWorkflow} startIcon={Icon.RELOAD} type={ButtonType.OUTLINED} />
              {isAllowedToEditWorkflow && <Button data-id="workflow_edit" href={WORKFLOW_BUILDER_ROUTE + `?${WORKFLOW_ID_QUERY_PARAM}=${workflowId}`} label="Edit Agent" startIcon={Icon.EDIT} type={ButtonType.OUTLINED} />}
            </>}

          <Badge bg="none" css={{
          "--tw-text-opacity": "1",
          "color": "rgb(155 163 191 / var(--tw-text-opacity))"
        }}>
            {workflow?.updatedAt ? formatDistanceToNowStrict(new Date(workflow?.updatedAt), {
            addSuffix: true
          }) : null}
          </Badge>
          {isMobileOnly && <NavButton data-id="nav_new_chat" hideActive icon={Icon.RELOAD} route={ROOT_ROUTE} title="" />}
          {!workflowId && !!chatId && <Button data-id="chat_share" label="Share Chat" onClick={openShareChatModal} startIcon={Icon.SHARE} type={ButtonType.OUTLINED} />}
        </ActionButtonsAndDateContainer>
      </WorkstationHeaderActionsRowContainer>
      <WorkstationTitle chatId={chatId} description={workflow?.description || undefined} refetchChat={refetchChat} title={title} />
    </WorkstationHeaderContainer>;
};
export default WorkstationHeader;