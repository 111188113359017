import _styled from "styled-components";
import { useState } from 'react';
import { Color, Typography, TypographySize, TypographyWeight, TextField, TextFieldType, IconButton, IconButtonType, Icon } from '~/components/core';
import { ToastType, useToast } from '~/hooks';
import { nextTrpc } from '~/trpc';
const WorkstationEditTitleContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "gap": "0.5rem",
  "paddingRight": "0.75rem"
});
const WorkstationTitleContainer = _styled.div({
  "display": "flex",
  "flexDirection": "column",
  "gap": "0.5rem",
  "borderBottomWidth": "1px",
  "borderColor": "#4d728038",
  "paddingBottom": "0.5rem",
  "paddingRight": "0.75rem",
  "paddingTop": "0.5rem"
});
const EditButtonsContainer = _styled.div({
  "display": "flex",
  "flexDirection": "row",
  "gap": "0.25rem"
});
interface WorkstationTitleProps {
  chatId: string | undefined;
  refetchChat: () => void;
  title: string;
  description?: string | undefined;
}
const WorkstationTitle: React.FC<WorkstationTitleProps> = ({
  chatId,
  refetchChat,
  title,
  description
}) => {
  // States
  const [editedChatTitle, setEditedChatTitle] = useState(title);
  const [chatTitle, setChatTitle] = useState(title);
  const [isEditing, setIsEditing] = useState(false);
  const {
    enqueueToast
  } = useToast();

  // Mutations
  const updateTitleMutation = nextTrpc.chats.updateTitle.useMutation({
    onMutate: () => {
      // Set the chat title to the input value to show the updated title in the UI optimistically
      setChatTitle(editedChatTitle);
    },
    onSuccess: () => {
      // Refetch the chat to get the latest title
      refetchChat();
      enqueueToast({
        message: 'Title updated successfully',
        type: ToastType.SUCCESS
      });
    },
    onError: () => {
      // Reset the chat title to the previous title if the mutation fails
      setChatTitle(title);
      enqueueToast({
        message: 'An error occurred while updating the title',
        type: ToastType.ERROR
      });
    },
    onSettled: () => {
      setIsEditing(false);
    }
  });

  // Handlers
  const handleSaveTitle = (newTitle: string) => {
    if (!chatId || newTitle.trim() === title) {
      setIsEditing(false);
      return;
    }
    if (newTitle.trim() === '') {
      setIsEditing(false);
      enqueueToast({
        message: 'Chat title cannot be empty.',
        type: ToastType.ERROR
      });
      return;
    }
    updateTitleMutation.mutate({
      chatId,
      title: newTitle
    });
  };
  const handleEditTitle = () => {
    setEditedChatTitle(title);
    setIsEditing(true);
  };
  return <WorkstationTitleContainer>
      <WorkstationEditTitleContainer>
        <EditButtonsContainer>
          {!isEditing && <IconButton color={Color.NAVY} data-id="workstation_title_edit" icon={Icon.EDIT} onClick={handleEditTitle} type={IconButtonType.SIMPLE} />}
          {isEditing && <>
              <IconButton color={Color.NAVY} data-id="workstation_title_save" icon={Icon.CHECK} onClick={() => handleSaveTitle(editedChatTitle)} type={IconButtonType.SIMPLE} />
              <IconButton color={Color.NAVY} data-id="workstation_title_cancel" icon={Icon.CLOSE} onClick={() => setIsEditing(false)} type={IconButtonType.SIMPLE} />
            </>}
        </EditButtonsContainer>
        {isEditing ? <TextField autoFocus fullWidth onChange={value => setEditedChatTitle(value)} onEnter={() => handleSaveTitle(editedChatTitle)} size={TypographySize.H3} textWeight={TypographyWeight.SEMI_NORMAL} type={TextFieldType.SIMPLE} value={editedChatTitle} /> : <Typography fullWidth onClick={handleEditTitle} size={TypographySize.H3} weight={TypographyWeight.SEMI_NORMAL}>
            {chatTitle}
          </Typography>}
      </WorkstationEditTitleContainer>
      {!!description && <Typography color={Color.GRAY}>{description}</Typography>}
    </WorkstationTitleContainer>;
};
export default WorkstationTitle;