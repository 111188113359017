import { formatDistanceToNowStrict } from 'date-fns';
import { Badge } from '../Badge';
import { Color, Icon, Icons, Typography, TypographySize } from '../core';
import { Dropdown, DropdownItem } from '../Dropdown';
import { DropdownItemProps } from '../Dropdown/DropdownItem';
import { CardOpacity, CardPadding, CardSize } from './Card.constants';
import { CardContainer, CardContent, CardMainContainer } from './Card.styles';
interface CardProps {
  children: React.ReactNode;
  size: CardSize;
  active?: boolean; // Enables hover state permanently
  category?: string;
  className?: string;
  creatorName?: string;
  dashed?: boolean;
  // resize only applies to medium
  description?: string | undefined;
  disableHoverEffect?: boolean;
  dropdownItems?: DropdownItemProps[];
  gradient?: boolean;
  onClick?: () => void;
  opacity?: CardOpacity;
  padding?: CardPadding;
  resize?: boolean;
  staticHoverStyles?: boolean;
  timestamp?: string;
}

// TODO: Make this component more generic,
// move workflow specific props/logic into WorkflowCard, move to core
const Card: React.FC<CardProps> = ({
  category,
  timestamp,
  creatorName,
  disableHoverEffect,
  active,
  dashed = false,
  gradient = true,
  size,
  opacity = CardOpacity.OPAQUE,
  padding = CardPadding.DEFAULT,
  children,
  dropdownItems,
  resize = false,
  staticHoverStyles = false,
  onClick
}) => {
  const isExpanded = size === CardSize.EXPANDED;
  return <CardContainer $dashed={dashed} $gradient={gradient} $resize={resize} $size={size} className="group" onClick={onClick}>
      {isExpanded || disableHoverEffect && active || staticHoverStyles ? <div css={{
      "position": "absolute",
      "left": "5%",
      "top": "0px",
      "display": "flex",
      "width": "90%",
      "backgroundImage": "linear-gradient(to right, var(--tw-gradient-stops))",
      "--tw-gradient-from": "#C3C1F8 var(--tw-gradient-from-position)",
      "--tw-gradient-to": "#FFA797 var(--tw-gradient-to-position)",
      "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to)",
      "padding": "0.25rem"
    }} /> : <div className={`to-lavender-pale from-orange-pale absolute left-[5%] top-0 hidden max-h-[6px] w-[90%] bg-gradient-to-r p-1 ${!disableHoverEffect && 'group-hover:block'}`} />}

      <CardMainContainer $dashed={dashed} $opacity={opacity} $padding={padding} $resize={resize} $size={size} className="group">
        <div css={{
        "display": "flex",
        "height": "100%",
        "flexDirection": "column",
        "gap": "0.75rem"
      }}>
          {!isExpanded && category && <div css={{
          "display": "flex",
          "flexDirection": "row",
          "justifyContent": "space-between"
        }}>
              <Badge bg="none" css={{
            "--tw-bg-opacity": "1",
            "backgroundColor": "rgb(225 223 255 / var(--tw-bg-opacity))",
            "fontWeight": "600",
            "--tw-text-opacity": "1",
            "color": "rgb(60 48 131 / var(--tw-text-opacity))"
          }} text="sm">
                {category}
              </Badge>

              {dropdownItems && <Dropdown disabled={!dropdownItems.length} trigger={<Icons color={Color.NAVY} icon={Icon.MORE} />}>
                  {dropdownItems.map(option => <DropdownItem key={option.title} destructive={option.destructive} icon={option.icon} onClick={option.onClick ?? (() => {})} title={option.title} />)}
                </Dropdown>}
            </div>}
          <CardContent $dashed={dashed} $size={size}>
            {children}
          </CardContent>
        </div>
        <div css={{
        "visibility": "hidden",
        ".group:hover &": {
          "visibility": "visible"
        }
      }}>
          {(timestamp || creatorName) && !isExpanded && <div css={{
          "display": "flex",
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "space-between"
        }}>
              <Typography color={Color.MID_NEUTRAL} size={TypographySize.X_SMALL}>
                {timestamp && <span>
                    {formatDistanceToNowStrict(new Date(timestamp), {
                addSuffix: true
              })}
                  </span>}
                {creatorName && <span>Created by: {creatorName}</span>}
              </Typography>
            </div>}
        </div>
      </CardMainContainer>
    </CardContainer>;
};
export default Card;